import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

import SectionTypography from "pages-sections/Components-Sections/SectionTypography.js";
import TeamSection from "pages-sections/LandingPage-Sections/TeamSection.js";

const UseStyles = makeStyles(styles);

export default function wspolnoty(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
          <SectionTypography />
          <TeamSection className={classes.container}/>
      </div>
    </Layout>
  );
}
